import React from 'react'
import "./Lstyle.css"

const Loader = () => {
	return (
		<div style={{ width: '100%', height: '100%', backgroundColor: 'black',display:"flex" ,flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
			<div class='spinner'></div>

			
		</div>
	);
};

export default Loader;
